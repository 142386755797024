import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';

@Component({
    selector: 'app-ampliar-banner',
    templateUrl: './ampliar-banner.page.html',
    styleUrls: ['./ampliar-banner.page.scss'],
})
export class AmpliarBannerPage implements OnInit {

    @ViewChild(IonSlides) slides: IonSlides;
    @Input() banner: any;

    sliderOpts = {
        zoom: true
    };

    constructor(private modalController: ModalController) { }

    ngOnInit() {}

    ionViewDidEnter() {
        this.slides.update();
    }

    async zoom(zoomIn: boolean) {
        const slider = await this.slides.getSwiper();
        const zoom = slider.zoom;
        zoomIn ? zoom.in() : zoom.out();
    }

    close() {
        this.modalController.dismiss();
    }

}
