import { Component, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { ViewerModalComponent } from 'ngx-ionic-image-viewer';
import { AppService } from 'src/app/services/app.service';
import { AmpliarBannerPage } from '../ampliar-banner/ampliar-banner.page';
import { AvisoService } from 'src/app/services/aviso.service';

@Component({
    selector: 'app-aviso',
    templateUrl: './aviso.page.html',
    styleUrls: ['./aviso.page.scss'],
})
export class AvisoPage implements OnInit {

    idaviso: number;
    aviso: any;

    constructor(
        public modalController: ModalController,
        private appService: AppService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private avisoService: AvisoService,
        private router: Router
    ) { }

    ngOnInit() {
        
    }

    ionViewDidEnter() {
        this.route.paramMap.subscribe(params => {
            let idaviso: string | number = params.get('idaviso');
            idaviso = parseInt(idaviso, 10);
            this.getAviso(idaviso);
        });
    }

    close() {
        this.modalController.dismiss();
    }

    getAviso(id) {
        this.appService.aviso(id).subscribe((response: any) => {
            if (response.dados) {
                this.aviso = response.dados.aviso;
                if(response.dados.total_nao_lidos)
                    this.avisoService.set(response.dados.total_nao_lidos);

                document.getElementById('content').addEventListener('click', (event: any) => {
                    if (event.target?.id === 'linkRedirect' && event.target?.dataset?.url) {
                        this.router.navigate([`/${event.target.dataset.url}`]);
                    }
                });
            }
        });
    }

    async ampliarImg(img) {
        const banner = { imagem_ampliada: img.src_g };
        const modal = await this.modalController.create({
            component: AmpliarBannerPage,
            componentProps: { banner },
        });

        return await modal.present();
    }

    getHtmlWithBypassedSecurity(code: string) {
        return this.sanitizer.bypassSecurityTrustHtml(code);
    }
}
