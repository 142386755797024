import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./page/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./page/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./page/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'area/:idArea/quiz/:idQuiz/start',
    loadChildren: () => import('./page/quiz/quiz.module').then(m => m.QuizPageModule)
  },
  {
    path: 'area/:idArea/quiz/:idQuiz/resultado',
    loadChildren: () => import('./page/resultado/resultado.module').then(m => m.ResultadoPageModule)
  },
  {
    path: 'area/:idArea/quiz/:idQuiz',
    loadChildren: () => import('./page/area-quiz/area-quiz.module').then(m => m.AreaQuizPageModule)
  },
  {
    path: 'area/:id',
    loadChildren: () => import('./page/area/area.module').then(m => m.AreaPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./page/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'resultado',
    loadChildren: () => import('./page/resultado/resultado.module').then(m => m.ResultadoPageModule)
  },
  {
    path: 'cadastro/:matricula',
    loadChildren: () => import('./page/cadastro/cadastro.module').then(m => m.CadastroPageModule)
  },
  {
    path: 'cadastro-distribuidor/:matricula/:cnpj',
    loadChildren: () => import('./page/cadastro/cadastro.module').then(m => m.CadastroPageModule)
  },
  {
    path: 'cadastro/:matricula/:action',
    loadChildren: () => import('./page/cadastro/cadastro.module').then(m => m.CadastroPageModule)
  },
  {
    path: 'pre-cadastro',
    loadChildren: () => import('./page/pre-cadastro/pre-cadastro.module').then(m => m.PreCadastroPageModule)
  },
  {
    path: 'ranking',
    loadChildren: () => import('./page/ranking/ranking.module').then(m => m.RankingPageModule)
  },
  {
    path: 'conteudos',
    loadChildren: () => import('./page/conteudos/conteudos.module').then(m => m.ConteudosPageModule)
  },
  {
    path: 'conteudos/:idarea',
    loadChildren: () => import('./page/conteudos/conteudos.module').then(m => m.ConteudosPageModule)
  },
  {
    path: 'jogos',
    loadChildren: () => import('./page/jogos/jogos.module').then(m => m.JogosPageModule)
  },
  {
    path: 'avisos',
    loadChildren: () => import('./page/avisos/avisos.module').then(m => m.AvisosPageModule)
  },
  {
    path: 'aviso/:idaviso',
    loadChildren: () => import('./page/aviso/aviso.module').then(m => m.AvisoPageModule)
  },
  {
    path: 'image-modal',
    loadChildren: () => import('./page/image-modal/image-modal.module').then(m => m.ImageModalPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./page/perfil/perfil.module').then(m => m.PerfilPageModule)
  },
  {
    path: 'pre-cadastro',
    loadChildren: () => import('./page/pre-cadastro/pre-cadastro.module').then(m => m.PreCadastroPageModule)
  },
  {
    path: 'duvidas',
    loadChildren: () => import('./page/duvidas/duvidas.module').then(m => m.DuvidasPageModule)
  },
  {
    path: 'listar-avisos',
    loadChildren: () => import('./page/listar-avisos/listar-avisos.module').then(m => m.ListarAvisosPageModule)
  },
  {
    path: 'aviso-ler',
    loadChildren: () => import('./page/aviso-ler/aviso-ler.module').then(m => m.AvisoLerPageModule)
  },
  {
    path: 'modal-page',
    loadChildren: () => import('./page/modal-page/modal-page.module').then( m => m.ModalPagePageModule)
  },
  {
    path: 'jogos-entrar',
    loadChildren: () => import('./page/jogos-entrar/jogos-entrar.module').then( m => m.JogosEntrarPageModule)
  },
  {
    path: 'pergunta-teste',
    loadChildren: () => import('./page/pergunta-teste/pergunta-teste.module').then( m => m.PerguntaTestePageModule)
  },
  {
    path: 'game-antes',
    loadChildren: () => import('./page/game-antes/game-antes.module').then( m => m.GameAntesPageModule)
  },
  {
    path: 'jogos-entrar-posicoes',
    loadChildren: () => import('./page/jogos-entrar-posicoes/jogos-entrar-posicoes.module').then( m => m.JogosEntrarPosicoesPageModule)
  },
  {
    path: 'jogos-area-segmento',
    loadChildren: () => import('./page/jogos-area-segmento/jogos-area-segmento.module').then( m => m.JogosAreaSegmentoPageModule)
  },
  {
    path: 'timeline',
    loadChildren: () => import('./page/timeline/timeline.module').then( m => m.TimelinePageModule)
  },
  {
    path: 'timeline-post',
    loadChildren: () => import('./page/timeline-post/timeline-post.module').then( m => m.TimelinePostPageModule)
  },
  {
    path: 'timeline-new-post',
    loadChildren: () => import('./page/timeline-new-post/timeline-new-post.module').then( m => m.TimelineNewPostPageModule)
  },
  {
    path: 'downloads',
    loadChildren: () => import('./page/downloads/downloads.module').then( m => m.DownloadsPageModule)
  },
  {
    path: 'downloads/:tipo',
    loadChildren: () => import('./page/downloads/downloads.module').then( m => m.DownloadsPageModule)
  },
  {
    path: 'downloads/:tipo/:area',
    loadChildren: () => import('./page/downloads/downloads.module').then( m => m.DownloadsPageModule)
  },
  {
    path: 'trade',
    loadChildren: () => import('./page/trade/trade.module').then( m => m.TradePageModule)
  },
  {
    path: 'modal-campanha',
    loadChildren: () => import('./page/modal-campanha/modal-campanha.module').then( m => m.ModalCampanhaPageModule)
  },
  {
    path: 'programa',
    loadChildren: () => import('./page/programa/programa.module').then( m => m.ProgramaPageModule)
  },
  {
    path: 'institucional',
    loadChildren: () => import('./page/institucional/institucional.module').then( m => m.InstitucionalPageModule)
  },
  {
    path: 'downloads-detalhes',
    loadChildren: () => import('./page/downloads-detalhes/downloads-detalhes.module').then( m => m.DownloadsDetalhesPageModule)
  },
  {
    path: 'aviso-detalhes',
    loadChildren: () => import('./page/aviso-detalhes/aviso-detalhes.module').then( m => m.AvisoDetalhesPageModule)
  },
  {
    path: 'ampliar-banner',
    loadChildren: () => import('./page/ampliar-banner/ampliar-banner.module').then( m => m.AmpliarBannerPageModule)
  },
  {
    path: 'ranking-fanaticos',
    loadChildren: () => import('./page/ranking-fanaticos/ranking-fanaticos.module').then( m => m.RankingFanaticosPageModule)
  },
  {
    path: 'privacidade',
    loadChildren: () => import('./page/privacidade/privacidade.module').then( m => m.PrivacidadePageModule)
  },
  {
    path: 'termos',
    loadChildren: () => import('./page/termos/termos.module').then( m => m.TermosPageModule)
  },
  {
    path: 'termo-modal',
    loadChildren: () => import('./page/termo-modal/termo-modal.module').then( m => m.TermoModalPageModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./page/user/user.module').then( m => m.UserPageModule)
  }, 
  {
    path: 'usuario',
    loadChildren: () => import('./page/user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },
  {
    path: 'usuario/:id',
    loadChildren: () => import('./page/user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },
  {
    path: 'ferramentas',
    loadChildren: () => import('./page/ferramentas/ferramentas.module').then( m => m.FerramentasPageModule)
  }, 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
