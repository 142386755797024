import { PdfViewerService } from './services/pdf-viewer.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { LottieAnimationViewModule } from 'ng-lottie';

import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { Clipboard } from '@ionic-native/clipboard/ngx';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { InterceptorHelpers } from './helpers/interceptor.helpers';

import { ComponentsModule } from 'src/app/components/components.module';

import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';


export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: 'app-parati-exercito',
        driverOrder: ['sqlite', 'indexeddb', 'websql']
    }),
    LottieModule.forRoot({ player: playerFactory }),
    LottieAnimationViewModule,
    NgxPanZoomModule,
      ComponentsModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    File,
    WebView,
    FilePath,
    FileTransfer,
    FileTransferObject,
    FileOpener,
    AndroidPermissions,
    SocialSharing,
    InAppBrowser,
    PdfViewerService,
    Clipboard,
    OneSignal,
    Deeplinks,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorHelpers, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
