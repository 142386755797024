import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { StorageService } from './services/storage.service';

import { enableProdMode, isDevMode } from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { AvisoPage } from 'src/app/page/aviso/aviso.page';
import { Router } from '@angular/router';


enableProdMode();
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private router: Router,
        private storageService: StorageService,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private deeplinks: Deeplinks,
        private navController: NavController
    ) {
        this.storageService.getUsuario();
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.setupPush();
            this.setupDeeplinks();
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }


    setupPush() {
        // I recommend to put these into your environment.ts
        //this.oneSignal.startInit('407f357d-ad97-4890-8711-aab4be20aa1d', '123325995781');
        //this.oneSignal.endInit();
        try {
            let _this = this;

            if (this.platform.is('desktop')){
                console.log('Platform is desktop');
            }
            if (this.platform.is('mobileweb')){
                console.log('Platform is mobileweb');
            }
            if (this.platform.is('android')){
                console.log('Platform is android');
            }
            if (this.platform.is('ios')){
                console.log('Platform is ios');
            }

            if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
                
                const clickNotification = async function(event) {
                    let notificationData = JSON.stringify(event);
            
                    console.log("NotificationData:", notificationData);
                };
            
                const listenerPushSubscriptionChange = async function(event) {
                    console.log("Push subscription changed: ", (event));
                    let pushSubscription = await OneSignal.User.pushSubscription;
                
                    console.log("Onesignal ID Listener", pushSubscription.id);
                    if(pushSubscription.id != null && pushSubscription.id != undefined && pushSubscription.id != ''){
                        _this.storageService.setUuid(pushSubscription.id);
                    }
                };

                // Uncomment to set OneSignal device logging to VERBOSE  
                // OneSignal.Debug.setLogLevel(6);

                // Uncomment to set OneSignal visual logging to VERBOSE
                // OneSignal.Debug.setAlertLevel(6);

                // NOTE: Update the init value below with your OneSignal AppId.
                OneSignal.initialize('407f357d-ad97-4890-8711-aab4be20aa1d');

                //Adds an event listener for clicks on notifications
                OneSignal.Notifications.addEventListener("click", clickNotification);

                OneSignal.Notifications.requestPermission(true).then((accepted) => {
                    console.log("User accepted notifications: " + accepted);
                    _this.storageService.setPushAcc(accepted);
                });

                OneSignal.User.pushSubscription.addEventListener("change", listenerPushSubscriptionChange);

                let pushSubscription = OneSignal.User.pushSubscription;

                console.log("PushSubscription: ", pushSubscription);

                console.log('OneSignal ID: ' + pushSubscription.id);

                if(pushSubscription.id != null && pushSubscription.id != undefined && pushSubscription.id != ''){
                    _this.storageService.setUuid(pushSubscription.id);
                }

                /*
                
                OneSignal.setAppId('407f357d-ad97-4890-8711-aab4be20aa1d');
                OneSignal.getDeviceState(function (stateChanges) {
                    console.log("Onesignal Id: ", stateChanges.userId);
                    _this.storageService.setUuid(stateChanges.userId);
                    // OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
                    //     console.log("User accepted notifications: " + accepted,stateChanges.userId);
                    // });
                });
                */
            }
        } catch (error) {
            console.log("OneSignalError: ",error);
        }
    }

    setupDeeplinks() {
        if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
            this.deeplinks.routeWithNavController(this.navController, {
                '/aviso/:idaviso': AvisoPage,
            }).subscribe(match => {
                // match.$route - the route we matched, which is the matched entry from the arguments to route()
                // match.$args - the args passed in the link
                // match.$link - the full link data
                let route = '/'+match.$link.host+'/'+match.$link.path;
                this.router.navigate([route]);
                console.log('Successfully matched route', match,route);
                console.log('route',route);
            }, nomatch => {
                // nomatch.$link - the full link data
                console.error('Got a deeplink that didn\'t match', nomatch);
            });
        }
    }
}
