import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError, from } from 'rxjs';
import { map, catchError, finalize, mergeMap } from 'rxjs/operators';

import { LoadingController, AlertController, Platform } from '@ionic/angular';

import { StorageService } from './../services/storage.service';
import { LoadingService } from './../services/loading.service';

@Injectable()
export class InterceptorHelpers implements HttpInterceptor {
    usuario;
    countAlert = 0;
    ehMobile = true;
    appVersion = '16';

    constructor(
        private storageService: StorageService,
        private loadingService: LoadingService,
        public loadingController: LoadingController,
        private alertController: AlertController,
        private router: Router,
        private plt: Platform,
        private oneSignal: OneSignal,
    ) {
        this.usuario = storageService.usuario;
        if (this.plt.is('desktop') || this.plt.is('mobileweb')) {
            this.ehMobile = false;
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const execRequest = this.execRequest(request, next);
        return from(execRequest).pipe(
            mergeMap( (req: Observable<HttpEvent<any>>) => {
                return req;
            })
        );
    }

    async execRequest(request: HttpRequest<any>, next: HttpHandler): Promise<any> {
        this.usuario = await this.storageService.usuario;
        let uuid = await this.storageService.getUuid();
        const tokenUsuario = this.usuario ? this.usuario.token : '';
        const email = this.usuario ? this.usuario.email : '';

        let async = false;
        if (request.url.includes('async=true')) {
            async = true;
        }

        if (next && !async) {
           await this.loadingService.on();
        }

        let headers = new HttpHeaders();
        if (!request.url.includes('upload_imagem')) {
            headers = new HttpHeaders()
                .append('X-Authorization', tokenUsuario)
                .append('Content-Type', 'application/json')
                .append('App', this.ehMobile.toString())
                .append('Versao', this.appVersion)
                .append('Uuid', uuid ? uuid : '')
                .append('Usuario', email);
        }
        request = request.clone({ headers });
        if (this.ehMobile && this.usuario) {
            const {token, matricula} = this.usuario;
            const versao = this.appVersion;
            this.oneSignal.sendTags({
                matricula, versao
            });
        }
        return this.handler(next, request, async);
    }

    handler(next, request, async) {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (!event.body.status && !async) {
                        this.trataError(event);
                    }
                    return event;
                }
            }),
            catchError(response => {
                if (!async) {
                    this.renderError();
                }
                return [];
            }),
            finalize(() => {
                this.loadingService.off();
            })
        );
    }

    private trataError(event: any) {
        let redirect = false;
        let msgError = '';
        let headerError = 'Atenção';

        if (event.body.msg) {
            msgError = event.body.msg;
        }

        if (event.body.redirect) {
            redirect = true;
        }

        if (event.body.headerError) {
            headerError = event.body.headerError;
        }

        if (event.body.code === 401) {
            this.storageService.deleteUsuario();
            this.router.navigate(['/login'], { replaceUrl: true });
            this.renderError(msgError, false, 'login', headerError);
            return true;
        }

        this.renderError(msgError, redirect, event.body.redirect, headerError);
    }

    async renderError(msg: string = '', redirect: boolean = false, page: string = '', headerError?: string) {

        let msgError = 'Não foi possivel executar operação. Tente novamente mais tarde';
        if (msg) {
            msgError = msg;
        }

        if (!headerError) {
            headerError = 'Atenção';
        }
        if (this.countAlert === 0) {
            this.countAlert++;
            this.alertController.dismiss();
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: headerError,
                message: msgError,
                buttons: [
                    {
                        text: 'Entendido',
                        cssClass: 'primary',
                        handler: async (e) => {
                            this.countAlert = 0;
                            if (page) {
                                if (page === 'login') {
                                    await this.storageService.deleteUsuario();
                                }
                                this.router.navigate([page]);
                            }
                        }
                    }
                ]
            });
            this.loadingService.off();
            await alert.present();
        }
    }
}
