import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

   right = '-500px';
   display = 'none';

  constructor(
    private router: Router,
    public storageService: StorageService,
    private menu: MenuController
  ) {

 }

 abrirMenu(){
  this.right='0';
  this.display="block";
}

  fecharMenu(){
   this.right='-500px';
   this.display="none";
  }

 goPage(page: string) {

     this.router.navigateByUrl(page);
 }

 openFirst() {
   this.menu.enable(true, 'first');
   this.menu.open('first');
 }

 openEnd() {
   this.menu.open('end');
 }

 openCustom() {
   this.menu.enable(true, 'custom');
   this.menu.open('custom');
 }

  ngOnInit() {
  }

}
