import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BrowserStack } from 'protractor/built/driverProviders';
@Injectable({
    providedIn: 'root'
})


export class LoadingService {
    constructor(public loadingController: LoadingController) { }

    async on() {
        await this.loadingController.create({
            cssClass: 'loading',
            showBackdrop: false,
            duration: 5000
        }).then((response) => {
            response.present();
        });
    }

    async off() {
        let topLoader = null;
        do{
            topLoader = await this.loadingController.getTop();
        }while (topLoader && (await topLoader.dismiss()));
    }
}
