import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { StorageService } from './../services/storage.service';
import { environment } from 'src/environments/environment.prod';



@Injectable({
    providedIn: 'root'
})
export class AppService {

    //  A url da aplicação agora está em um arquivo específico para variavel de ambiente 'environments/environment.prod.ts'.
    //  Ajustar a url base nesse arquivo e ajustar se aqui se vai apontar para produção ou debug

    urlApi = `${environment.urlProd}extranet/api/`;
    // urlApi = `${environment.urlDebug}extranet/api/`;
    // urlApi = `https://beta.exercitodopdv.com.br/extranet/api/`;
    // urlApi = `http://192.168.200.240/parati-exercito-pdv/web/extranet/api/`;
    // urlApi = `http://localhost/parati-exercito-pdv/web/extranet/api/`;

    constructor(
        private alertController: AlertController,
        private http: HttpClient,
        private storageService: StorageService
    ) {
        this.storageService.getUsuario();
    }

    private request(method: string, url: string, param: object = null) {
        let request = null;

        if (method === 'get') {
            request = this.http.get<any>(`${this.urlApi + url}`);
        }

        if (method === 'post') {
            request = this.http.post(
                `${this.urlApi + url}`,
                JSON.stringify(param)
            );
        }

        return request;
    }

    async asyncRespostaPergunta(resposta: object) {
        try {
            this.request('post', `resposta_pergunta?async=true`, resposta).subscribe((res) => {
            });
        } catch (error) { }
    }

    respostaQuiz(resposta: any): Observable<object> {
        resposta.email = this.storageService.usuario.email;
        return this.request('post', `resposta_quiz`, resposta);
    }

    inicialLogado(param: any): Observable<object> {
        return this.request('get', `inicial_logado?versao=${param.versao}&plataforma=${param.plataforma}`);
    }

    getJogos(): Observable<object> {
        return this.request('get', `jogos`);
    }

    getJogosAreaSegmento(idArea, idSegmento): Observable<object> {
        return this.request('get', `jogosAreaSegmento?idarea=${idArea}&idsegmento=${idSegmento}`);
    }

    getDuvidas(): Observable<object> {
        return this.request('get', `duvidas`);
    }

    enviaDuvidas(duvida): Observable<object> {
        return this.request('post', `envia_duvidas`, duvida);
    }

    dadosArea(id): Observable<object> {
        return this.request('get', `dados_area?id=${id}`);
    }

    dadosQuiz(areaQuiz): Observable<object> {
        return this.request('get', `dados_quiz?id_area=${areaQuiz.idarea}&id_quiz=${areaQuiz.idquiz}`);
    }

    login(user: object): Observable<object> {
        return this.request('post', `login`, user);
    }

    conteudos(id, tipoFiltro): Observable<object> {
        let filtro = '';
        if (id && tipoFiltro === 'area') {
            filtro = `?id_area=${id}`;
        }
        if (id && tipoFiltro === 'segmento') {
            filtro = `?id_segmento=${id}`;
        }
        return this.request('get', `conteudos${filtro}`);
    }

    conteudoDetalhes(idConteudo): Observable<object> {
        return this.request('get', `conteudo?id_conteudo=${idConteudo}`);
    }

    pontuacaoPerfil(): Observable<object> {
        return this.request('get', `pontuacao_perfil`);
    }

    upload(formData) {
        return this.request('post', `upload_imagem`, formData);
    }

    uploadWeb(files: Set<File>, modulo) {
        const formData = new FormData();
        files.forEach(file => formData.append('file', file, file.name));
        return this.http.post(`${this.urlApi}upload_imagem?modulo=${modulo}`, formData);
    }

    uploadMobile(files: FormData, modulo) {
        return this.http.post(`${this.urlApi}upload_imagem?modulo=${modulo}`, files);
    }

    validaCNPJCadastro(cnpj) {
        return this.request('get', `valida_cnpj_distribuidor?cnpj=${cnpj}`);
    }

    validaCPFCadastro({ cnpj, cpf }) {
        return this.request('get', `valida_cpf_distribuidor?cnpj=${cnpj}&cpf=${cpf}`);
    }
    // validaEmailCadastro(matricula) {
    //   return this.request('get', `valida_email_cadastro?matricula=${matricula}`);
    // }
    validaEmailCadastro(matricula) {
        return this.request('get', `valida_email_cadastro?matricula=${matricula}`);
    }

    validaSenhaCadastro(user) {
        return this.request('post', `valida_senha_cadastro`, user);
    }

    salvaFormularioCadastro(user) {
        return this.request('post', `salva_formulario_cadastro`, user);
    }

    buscaDadosCadastro(matricula, action, cnpj) {
        return this.request('get', `busca_dados_cadastro?matricula=${matricula}&action=${action}&cnpj=${cnpj}`);
    }

    buscaCidadesCadastro(idestado) {
        return this.request('get', `busca_cidades_cadastro?idestado=${idestado}`);
    }

    dadosRank(tipoFiltro) {
        return this.request('get', `rank?filtro=${tipoFiltro}`);
    }

    getPosts({ numReg, tipoQuiz, pagina, categoriaExecucao }): Observable<object> {
        return this.request('get', `posts?categoria=${tipoQuiz}&num_reg=${numReg}&pagina=${pagina}&categoria_execucao=${categoriaExecucao}`);
    }

    postLike(id): Observable<object> {
        return this.request('get', `post_like?idpost=${id}`);
    }

    cadastraPost(form) {
        return this.request('post', `post_salvar`, form);
    }

    downloadDados(tipo) {
        return this.request('get', `download_dados?menu=${tipo}`);
    }

    downloadArquivos({ mes, ano, ano_mes, categoria, area, numReg, pagina, tipo, marca }) {
        return this.request('get', `download_arquivos?mes=${mes}&pagina=${pagina}&ano=${ano}&area=${area}&num_reg=${numReg}&categoria=${categoria}&tipo=${tipo}&marca=${marca}&ano_mes=${ano_mes}`);
    }

    downloadArquivoItensZip(id){
        return this.request('get', `download_arquivo_itens_zip?id=${id}`);
    }

    getCanais() {
        return this.request('get', `post_canais`);
    }
    getCategoriasExecucao() {
        return this.request('get', `post_categorias`);
    }
    getBanners() {
        return this.request('get', `banners`);
    }
    incentivoCampanhas() {
        return this.request('get', `incentivo_campanhas`);
    }
    incentivoFotos() {
        return this.request('get', `incentivo_fotos`);
    }

    incentivoSalvarFoto(form) {
        return this.request('post', `incentivo_salvar_foto`, form);
    }
    reactPost(idpost, reacao) {
        return this.request('get', `post_react?idpost=${idpost}&reacao=${reacao}`);
    }
    avisos(page) {
        return this.request('get', `avisos?pagina=${page}`);
    }
    aviso(id) {
        return this.request('get', `aviso?id=${id}`);
    }
    downloadItens(id) {
        return this.request('get', `download_arquivo_itens?id=${id}`);
    }
    getCEP(cep) {
        return this.request('get', `cep_buscar?cep=${cep}`);
    }
    getTermos() {
        return this.request('get', `termo_buscar`);
    }
    getEstadosSelect() {
        return this.request('get', `estado_buscar`);
    }
    getCidadesSelect(estado: string) {
        return this.request('get', `cidade_buscar?uf=${estado}`);
    }
    aceitaTermos(termos) {
        return this.request('post', `termo_salvar`, termos);
    }
    excluirCadastro() {
        return this.request('get', `excluir_cadastro`);
    }
    buscaUsuarios({pagina, textoBusca}) {
        return this.request('get', `usuarios?pagina=${pagina}&busca=${textoBusca}`);
    }    
    buscaUsuarioById(id, pagina, numReg) {
        console.log(id, pagina, numReg);
        return this.request('get', `usuario?pagina=${pagina}&idusuario=${id}&num_reg=${numReg}`);
    }
    post_favorito(id) {
        return this.request('get', `post_favorito?id=${id}`);
    }
}
