import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AvisoService {

    private total = 0;

    constructor() { }

    set(valor: number) {
        this.total = valor;
    }

    get() {
        return this.total;
    }

}
