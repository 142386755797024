import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

import { MenuController } from '@ionic/angular';
import { AvisoService } from 'src/app/services/aviso.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    usuario: any;
    logado = false;

    right = '-500px';
    display = 'none';

    constructor(
        private router: Router,
        public storageService: StorageService,
        private menu: MenuController,
        public avisoService: AvisoService
    ) { }

    ngOnInit() {
        const user = this.storageService.getUsuario();
        user.then(usuario => {
            if (usuario) {
                this.usuario = usuario;
                this.logado = true;
            }
        });

        // console.log('Footer Init: ', this.usuario, user);
    }

    abrirMenu() {
        this.right = '0';
        this.display = 'block';
    }

    fecharMenu() {
        this.right = '-500px';
        this.display = 'none';
    }

    goPage(page: string) {
        if (page === 'home' && !this.logado) {
            page = 'login';
        }
        this.router.navigateByUrl(page);
    }

    logout() {
        this.usuario = null;
        this.logado = false;
        this.storageService.deleteUsuario();
        this.router.navigate(['/login'], { replaceUrl: true });
    }

    regrasRenderMenu() {
        const naoRenderizaMenu = ['/termos'];
        const url = this.router.url;        
        return this.logado && !naoRenderizaMenu.includes(url);
    }

    goUsuario() {

        // console.log("Go Usuario: ", this.usuario);

        const rota = '/usuario/' + this.usuario.idusuario;
        this.router.navigate([rota]);
    }


}
