import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';

import { Usuario } from '../interfaces/usuario';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    storageNameApresentacao = 'app-parati-exercito-apresentacao';
    storageNameUuid = 'app-parati-exercito-uuid';
    storageNameAcc = 'app-parati-exercito-push-accepted';
    apresentacao: any;

    storageNameUser = 'app-parati-exercito';    
    usuario: Usuario;
    uuid: any = '';
    pushAcc: any = '';

    constructor(private storage: Storage) { }

    async setUsuario(usuario: Usuario) {
        await this.storage.set(this.storageNameUser, usuario);
    }

    async getUsuario() {
        if (!this.usuario) {
            await this.storage.get(this.storageNameUser).then((usuarioStorage: Usuario) => {
                if (usuarioStorage) {
                    this.usuario = usuarioStorage;
                }
            });
        }
        return this.usuario;
    }

    async deleteUsuario() {
        this.usuario = null;
        await this.storage.clear();
    }

    async setApresentacao(apresentacao) {
        await this.storage.set(this.storageNameApresentacao, apresentacao);
        this.apresentacao = apresentacao;
    }

    async getApresentacao() {
        if (!this.apresentacao) {
            this.apresentacao = await this.storage.get(this.storageNameApresentacao);
        }
        return this.apresentacao;
    }

    async setUuid(uuid) {
        await this.storage.set(this.storageNameUuid, uuid);
        this.uuid = uuid;
    }

    async setPushAcc(accepted) {
        await this.storage.set(this.storageNameAcc, accepted);
        this.pushAcc = accepted;
    }

    async getUuid() {
        if (!this.uuid) {
            await this.storage.get(this.storageNameUuid).then((uuid: any) => {
                if (uuid) {
                    this.uuid = uuid;
                }
            });
        }

        return this.uuid;
    }
}
